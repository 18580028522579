<template>
	<v-overlay :visible="visible" @close="$emit('close')">
		<div class="flex space-between align-center mb-10">
			<h1>Faktura</h1>

			<div class="overlay-close" @click.prevent="$emit('close')"></div>
		</div>

		<div style="position: relative; padding-bottom: 140%">
			<iframe v-if="blob" :src="blob" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"></iframe>
		</div>

		<div class="mb-10 mt-10">
			<label class="inline-flex align-center cursor-pointer">
				<div class="switch" :class="{ active: preventEconomicRequest }">
					<input type="checkbox" v-model="preventEconomicRequest" />

					<div></div>
				</div>

				<span class="ml-5">Ikki send faktura til e-conomic</span>
			</label>
		</div>

		<div class="flex justify-end mt-20">
			<div class="btn btn-white small white mr-10 border" @click="openPdf">
				Opna í nýggjum tabbi

				<i class="ml-10 fas fa-file-pdf"></i>
			</div>

			<div class="btn small" :class="{ disabled: loading }" @click="sendInvoice">
				<i v-if="loading" class="fas fa-spinner fa-spin color-white mr-10"></i>

				Send faktura

				<i class="ml-10 far fa-paper-plane"></i>
			</div>
		</div>
	</v-overlay>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';

import createTransactionPDF from '@/helpers/create-transaction-pdf';

export default {
	name: 'PdfOverlay',

	components: {
		VOverlay,
	},

	props: {
		visible: Boolean,
		data: Object,
		loading: Boolean,
	},

	data() {
		return {
			blob: null,
			preventEconomicRequest: false,
		};
	},

	watch: {
		data: {
			deep: true,
			handler: function (value) {
				if (!value) {
					return;
				}

				this.blob = createTransactionPDF(this.data);
			},
		},
	},

	methods: {
		sendInvoice() {
			this.$emit('send-invoice', this.preventEconomicRequest);
		},

		openPdf() {
			window.open(this.blob);
		},
	},
};
</script>
