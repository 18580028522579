<template>
	<v-overlay :visible="visible" @close="$emit('close')">
		<div class="flex space-between align-center mb-50">
			<div>
				<h1 class="mb-10">Legg upp EÅR fílu</h1>
			</div>

			<div class="overlay-close" @click.prevent="$emit('close')"></div>
		</div>

		<div class="mb-30">
			<a href="#" class="relative flex space-between p-20 align-center background-white border-light rounded-lg hover:background-ultra-light-grey-300">
				<input class="opacity-0 absolute top left w-100 h-100 cursor-pointer" type="file" ref="file" name="file" @change="fileChange" />

				<p v-if="!file">Viðheft fílu</p>
				<p v-if="file">{{ file.name }}</p>

				<i class="fas fa-upload"></i>
			</a>
		</div>

		<div class="flex space-between">
			<div class="btn inline-block" :class="{ disabled: !file }" @click="uploadFile">Legg upp</div>
		</div>
	</v-overlay>
</template>

<script>
import VOverlay from '@/components/VOverlay.vue';
import axios from 'axios';

export default {
	name: 'TransactionsOverlay',

	props: {
		visible: Boolean,
		link: Boolean,
		agreement: Object,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			loading: false,
			file: null,
		};
	},

	methods: {
		fileChange(e) {
			const file = e.target.files[0];

			if (file.type === 'text/plain') {
				this.file = file;
			}
		},

		uploadFile() {
			if (!this.file) {
				return;
			}

			this.loading = true;

			var formData = new FormData();
			formData.append('file', this.file);

			axios
				.post('/transactions', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					this.$emit('change', response.data);
					this.$emit('close');

					this.file = null;
				})
				.catch((error) => {
					console.log(`%Error when trying to fetch transactions in TransactionsOverlay.vue`, 'font-size: 14px; font-weight: 700; background: red; color: white;');

					console.log(error);
				});
		},
	},
};
</script>
