<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Uppgerðir</h1>

				<p class="color-grey">Yvirlit yvir allar uppgerðir</p>
			</div>

			<div class="flex align-center">
				<div class="mr-10 relative search-input small" title="Leita eftir navni á kunda, terminali ella kortnummari.">
					<input
						type="text"
						ref="searchInput"
						class="focus:background-light-grey-100"
						v-model="searchString"
						@keydown.esc="searchString = ''"
						placeholder="Leita"
					/>

					<div class="icon-search"></div>
				</div>

				<v-date-picker v-model="range" mode="dateTime" is24hr is-range :minute-increment="5" :popover="{ visibility: 'focus' }">
					<template v-slot="{ inputValue, inputEvents }">
						<div class="date-picker-inputs" v-on="inputEvents.start">
							<input type="text" class="date-input" :value="durationText(inputValue)" placeholder="Tíðarskeið" />

							<div class="date-picker-icon" v-if="!durationText(inputValue)"><i class="fa fa-calendar-alt"></i></div>

							<div class="date-picker-icon" v-if="durationText(inputValue)" @click="clearDateFilter()"><i class="fas fa-times"></i></div>
						</div>
					</template>
				</v-date-picker>

				<div class="btn-add cursor-pointer" @click="openOverlay" title="Legg upp EÅR fílu"></div>

				<router-link :to="{ name: 'AccountSettlements' }" class="btn-round ml-5" title="Kontu uppgerðir">
					<i class="fas fa-table"></i>
				</router-link>
			</div>
		</div>

		<div class="flex justify-center mt-80" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<div v-if="!loading && !agreements.length && !searchString">
			<div class="mt-70 text-center">Ongar uppgerðir vóru funnar<strong v-if="range"> í ásetta tíðarskeiðinum</strong>.</div>
		</div>

		<div v-if="!loading && searchString">
			<div class="mt-70 mb-30 text-center">
				Tú hevur leitað eftir "<strong>{{ searchString }}</strong
				>". Tískil eru uppgerðirnar ikki fulkomnar.
			</div>
		</div>

		<div class="agreement mb-20 pb-20" v-for="(agreement, index) in agreements" :key="`agreement-${index}`">
			<div class="flex space-between align-center cursor-pointer py-20" @click="toggleAgreement(agreement)">
				<div class="flex align-center">
					<h2 v-text="agreement[0]"></h2>

					<div class="ml-15 color-grey-400">
						{{ getAgreementType(agreement[1][0].agreement_typeId) }}
						<span v-if="agreement[1][0].agreement_isShortTermAgreement">(Vikuskiftisleiga)</span>
					</div>
				</div>

				<div>
					<router-link
						v-if="getAgreementId(agreement)"
						:to="{ name: 'AgreementShow', params: { id: getAgreementId(agreement) } }"
						class="hover:color-blue mr-10"
					>
						Far til avtalu

						<i class="fas fa-arrow-right ml-5"></i>
					</router-link>

					<i class="ml-10 fas" :class="expandedAgreement == agreement[0] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
				</div>
			</div>

			<table class="mt-10" v-if="expandedAgreement == agreement[0]">
				<thead>
					<th>Dato</th>

					<th>Terminalur</th>

					<th>Kort slag</th>

					<th>Kort nummar</th>

					<th class="right">Upphædd</th>

					<th></th>
				</thead>

				<tbody>
					<template v-for="(transaction, transactionIndex) in agreement[2]">
						<tr :key="`${index} ${transactionIndex}`" @click="toggleVisible(transaction)" class="cursor-pointer">
							<td>{{ getTransactionDate(transaction) }}</td>

							<td>{{ getTransactionTerminalId(transaction) }}</td>

							<td>-</td>

							<td>-</td>

							<td class="right">{{ getTransactionTotal(transaction).format(2) }}</td>

							<td><i class="ml-10 fas" :class="expandedDate == transaction[0] ? 'fa-chevron-up' : 'fa-chevron-down'"></i></td>
						</tr>

						<tr v-for="(subtransaction, index) in transaction[1]" class="subtransaction" :key="`sub ${index} ${transactionIndex}`">
							<td v-if="expandedDate == transaction[0]">
								{{ new Date(subtransaction.purchaseDateFaroeseTime).format('%d.%m.%y kl. %H:%M') }}
							</td>

							<td v-if="expandedDate == transaction[0]">{{ subtransaction.terminalId }}</td>

							<td v-if="expandedDate == transaction[0]">{{ subtransaction.cardType }}</td>

							<td v-if="expandedDate == transaction[0]">
								{{ subtransaction.pan.replace(/X/g, ' * ') }}
							</td>

							<td v-if="expandedDate == transaction[0]" class="right">{{ Number(subtransaction.purchaseAmount).format(2) }}</td>

							<td v-if="expandedDate == transaction[0]"></td>

							<div
								v-if="expandedDate == transaction[0]"
								class="subtransaction-delete"
								title="Avgreið"
								@click="
									markAsComplete(subtransaction.id, transaction);
									transaction[1] = [];
								"
							>
								<i class="fas fa-money-bill-wave color-grey hover:color-red cursor-pointer"></i>
							</div>
						</tr>
					</template>
				</tbody>
			</table>

			<InvoiceLine v-if="agreement[0] && expandedAgreement == agreement[0]" :products="products" :agreement="agreement" />

			<div v-if="expandedAgreement == agreement[0]" class="pb-20"></div>
		</div>

		<TransactionsOverlay :visible="overlayVisible" @change="onTransactionsUploaded" @close="overlayVisible = false" />
	</main>
</template>

<script>
import TransactionsOverlay from '@/components/TransactionsOverlay.vue';
import InvoiceLine from '@/components/InvoiceLine.vue';

import axios from 'axios';

export default {
	name: 'Transactions',

	components: {
		TransactionsOverlay,
		InvoiceLine,
	},

	data() {
		return {
			loading: true,
			transactions: [],
			products: [],
			agreements: [],
			agreementMap: new Map(),
			terminals: [],
			selectedTransactions: [],
			customerSearchString: '',
			test: [],
			agreementTypes: [],

			searchString: '',
			debounce: null,

			overlayVisible: false,

			expandedAgreement: null,
			expandedDate: null,

			range: {
				start: '',
				end: '',
			},
		};
	},

	computed: {
		//
	},

	watch: {
		range: {
			deep: true,
			handler: async function (value) {
				this.agreements = [];

				this.loading = true;

				if (!value) {
					await axios
						.get('/transactions')
						.then((response) => {
							const { transactions, products } = response.data;

							this.transactions = transactions;
							this.products = products;

							this.mapTransactions(transactions);

							this.loading = false;
						})
						.catch((error) => {
							console.log('error', error);
						});

					return;
				}

				await axios
					.get(`/transactions?from=${value.start}&to=${value.end}`)
					.then((response) => {
						const { transactions, products } = response.data;

						this.transactions = transactions;
						this.products = products;

						this.mapTransactions(transactions);

						this.loading = false;
					})
					.catch((error) => {
						console.log('error', error);
					});
			},
		},

		searchString: function (value) {
			if (!value) {
				this.mapTransactions(this.transactions);

				return;
			}

			clearTimeout(this.debounce);

			this.debounce = setTimeout(() => {
				const transactions = this.transactions.filter((transaction) => {
					return (
						transaction.terminal_verifoneNumber?.includes(value) ||
						transaction.terminal_verifoneSerialNumber?.includes(value) ||
						transaction.pan?.includes(value) ||
						transaction.customer_name?.includes(value)
					);
				});

				this.mapTransactions(transactions);
			}, 250);
		},
	},

	async mounted() {
		this.$refs.searchInput.focus();

		axios
			.get(`/agreement-types`)
			.then((response) => {
				this.agreementTypes = response.data;
			})
			.catch((error) => {
				console.log('error', error);
			});

		axios
			.get('/transactions')
			.then((response) => {
				const { transactions, products } = response.data;

				this.transactions = transactions;

				this.products = products;

				this.mapTransactions(transactions);

				this.loading = false;
			})
			.catch((error) => {
				console.log('error', error);
			});
	},

	methods: {
		getAgreementType(agreementTypeId) {
			if (!this.agreementTypes.length) {
				return '';
			}

			return this.agreementTypes.find((agreementType) => agreementType.id == agreementTypeId)?.name;
		},

		getAgreementId(transaction) {
			return transaction[1][0].agreement_id;
		},

		mapTransactions(transactions) {
			this.agreementMap = new Map();
			this.agreements = [];

			if (!transactions.length) {
				return;
			}

			transactions.sort((a, b) => {
				return new Date(a.purchaseDateFaroeseTime).getTime() - new Date(b.purchaseDateFaroeseTime).getTime();
			});

			for (let transaction of transactions) {
				let agreementMapKey = transaction.customer_name ?? '';

				// Get the agreement map, add the transaction to list of transactions and add the terminal to the list of terminals
				if (this.agreementMap.get(agreementMapKey)) {
					const agreementMap = this.agreementMap.get(agreementMapKey);

					// Add transaction to the list of transactions
					agreementMap.push(transaction);

					// Sum the total purchase amount for the agreement
					agreementMap.totalPurchaseAmount += Number(transaction.purchaseAmount);
				}

				// Initialize the agreement map. Add the transaction and agreement to the map object
				if (!this.agreementMap.get(agreementMapKey)) {
					this.agreementMap.set(agreementMapKey, [transaction]);
				}
			}

			this.agreements = [...this.agreementMap];

			this.agreements = this.agreements.filter((agreement) => {
				if (agreement[0] == '') {
					agreement[0] = 'Ókend avtala';
				}

				return agreement;
			});

			for (const agreement of this.agreements) {
				const dateMap = new Map();

				for (const transaction of agreement[1]) {
					const date = new Date(transaction.purchaseDateFaroeseTime).format('%d/%m/%y');
					const key = `${date} ${transaction.terminalId}`;

					if (!dateMap.get(key)) {
						dateMap.set(key, []);
					}

					dateMap.set(key, [...dateMap.get(key), transaction]);
				}

				agreement[2] = [...dateMap];
			}

			this.agreements = this.agreements.sort((a, b) => {
				if (a > b) {
					return 1;
				}
				if (b > a) {
					return -1;
				}

				return 0;
			});
		},

		onTransactionsUploaded() {
			window.location.reload();
		},

		openOverlay() {
			this.overlayVisible = true;
		},

		clearDateFilter() {
			this.range = null;
		},

		durationText({ start, end }) {
			if (!(start && end)) {
				return '';
			}

			return `${start} - ${end}`;
		},

		getTransactionDate(transaction) {
			return transaction[0].split(' ')[0];
		},

		getTransactionTerminalId(transaction) {
			return transaction[0].split(' ')[1];
		},

		getTransactionTotal(transaction) {
			const transactions = transaction[1];

			let total = 0;

			for (const transaction of transactions) {
				total += +transaction.purchaseAmount;
			}

			return total;
		},

		getTransactionTransactions(transaction) {
			return transaction[1].transactions;
		},

		toggleVisible(e) {
			if (this.expandedDate == e[0]) {
				return (this.expandedDate = null);
			}

			this.expandedDate = e[0];
		},

		toggleAgreement(agreement) {
			if (this.expandedAgreement == agreement[0]) {
				return (this.expandedAgreement = null);
			}

			this.expandedAgreement = agreement[0];
		},

		markAsComplete(transactionId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			this.transactions = this.transactions.filter((transaction) => transaction.id != transactionId);

			this.mapTransactions(this.transactions);

			axios
				.patch(`/transactions/${transactionId}/mark-as-complete`)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.agreement {
	padding: 0 20px;
	background: #f0f3f5;
	border: 1px solid #e8e8e8;
	border-radius: 8px;
}

.agreement-title {
	font-size: 22px;
}

.date-picker-inputs {
	position: relative;

	display: flex;
	gap: 5px;

	margin-right: 10px;

	input {
		padding: 16px 24px !important;
		padding-right: 40px !important;

		border-radius: 50px !important;
	}

	.date-picker-icon {
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);

		font-size: 18px;

		color: var(--color-grey);

		cursor: pointer;
	}
}

.subtransaction {
	position: relative;
	font-size: 13px;

	&:hover {
		td {
			background: #edf0f3 !important;
		}
	}

	td {
		font-weight: 400 !important;
		background: #f0f3f5 !important;
	}
}

.subtransaction-delete {
	position: absolute;
	top: 50%;
	right: 12px;

	transform: translateY(-50%);
}

table {
	th {
		width: 20%;
	}

	th:empty {
		width: 5%;
	}

	tr {
		&:hover {
			td {
				background: #fafafa !important;
			}
		}

		td {
			background: white;
			border-bottom: 2px solid #f0f3f5;
		}

		td.no-style {
			background: transparent;
			padding: 0;
			padding-bottom: 10px;

			&:hover {
				background: transparent !important;
			}
		}
	}
}
</style>
