import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import logo from '@/assets/terminal.png';

export default function createTransactionPDF(data) {
	const doc = new jsPDF();

	var img = new Image();
	img.src = logo;

	doc.addImage(img, 'png', 16, 15, 27, 6);

	doc.autoTable({
		theme: 'plain',
		margin: { bottom: 20 },
		columnStyles: {
			0: { halign: 'right' },
		},
		head: [[{ content: 'Uppgjørt tann', styles: { halign: 'right' } }]],
		body: [[new Date().format('%-d. %mn %y')]],
	});

	doc.autoTable({
		theme: 'plain',
		margin: { bottom: 20 },
		columnStyles: {
			2: { halign: 'right' },
		},
		head: [['Kundi', 'Teldupostur', { content: 'Konta', styles: { halign: 'right' } }]],
		body: [data.customer],
	});

	if (data.bankComment) {
		doc.autoTable({
			theme: 'plain',
			margin: { bottom: 30 },
			columnStyles: {
				2: { halign: 'right' },
			},
			head: [['Viðmerking til bankaflyting']],
			body: [[data.bankComment]],
		});
	}

	doc.setFont('Helvetica', 'italic');
	doc.setFontSize(7);

	doc.text(15, doc.lastAutoTable.finalY + 5, 'Allar upphæddir eru við MVG');

	doc.autoTable({
		styles: { halign: 'right' },
		margin: { top: 50 },
		columnStyles: {
			0: { halign: 'left' },
		},
		head: [[{ content: 'Terminalur', styles: { halign: 'left' } }, 'Dato', 'Søla', 'Kortkost.', 'Flyting', 'Saldo']],
		body: data.transactions,
	});

	doc.setTextColor('#686868');
	doc.setFontSize(9);

	doc.text(
		'Terminal.fo | Rossagøta 4 kj. 420 Hósvík | +298 780 880 | terminal@terminal.fo',
		doc.internal.pageSize.width / 2,
		doc.internal.pageSize.getHeight() - 8,
		'center'
	);

	return doc.output('bloburl');
}
